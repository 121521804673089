<template>
  <nav class="menu-items d-block">
    <ExpansionPanel>
      <template #title>
        <span class="fw-semibold">{{
          $label('settings.topNavItems.books')
        }}</span>
      </template>

      <template #body>
        <NuxtLink
          to="/boeken"
          class="menu-links menu-links--submenu d-block mb-8 fw-normal"
          @click.enter="closeMenu()"
        >
          {{ $label('settings.topNavItems.currentSelection') }}
        </NuxtLink>
        <NuxtLink
          to="/archief"
          class="menu-links menu-links--submenu d-block fw-normal"
          @click.enter="closeMenu()"
        >
          {{ $label('settings.topNavItems.archive') }}
        </NuxtLink>
      </template>
    </ExpansionPanel>

    <NuxtLink to="/shop" class="menu-links" @click.enter="closeMenu()">
      {{ $label('settings.topNavItems.shop') }}
    </NuxtLink>
    <NuxtLink
      to="/promo/verzilver"
      class="menu-links"
      @click.enter="closeMenu()"
    >
      {{ $label('settings.topNavItems.redeem') }}
    </NuxtLink>
    <NuxtLink to="/help" class="menu-links" @click.enter="closeMenu()">
      {{ $label('settings.topNavItems.help') }}
    </NuxtLink>

    <div v-if="loggedInUser" class="account-menu mt-40 h-100">
      <div class="user-icon__wrapper">
        <UserIcon class="mb-16" width="24px" height="24px" />
      </div>
      <BaseMenuUser menu="defaultMenu" />
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseMenuUser from '~/components/BaseMenuUser'
import ExpansionPanel from '~/components/ExpansionPanel'
import UserIcon from '~/assets/icons/user.svg'

export default {
  components: {
    BaseMenuUser,
    ExpansionPanel,
    UserIcon
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'loggedInUser'
    })
  },
  methods: {
    ...mapActions({
      updateDrawerState: 'updateDrawerState'
    }),
    closeMenu() {
      this.updateDrawerState({
        isDrawerShown: false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-links {
  font-size: 16px;
  text-decoration: none;
}

a {
  display: block;
  padding: 12px 0;
  opacity: 0;

  &::first-letter {
    text-transform: uppercase;
  }
}

a,
.user-icon__wrapper {
  border-bottom: 1px solid $grey-01;
}

@for $i from 1 through 8 {
  a:nth-child(#{$i}n) {
    animation: fadeInUp 0.3s forwards ease-out;
    animation-delay: #{$i * 0.2}s;
  }
}

.user-icon__wrapper {
  opacity: 0;
  animation: fadeInUp 0.3s forwards ease-out;
  animation-delay: 0.8s;
}

.account-menu {
  max-height: 400px;
}

:deep(.expansion-panel) {
  border-top: none;
  border-bottom: 1px solid $grey-01;

  .expansion-panel__title {
    font-weight: normal !important;
    padding: 16px 0;
    font-size: 16px;
  }

  .expansion-panel__body {
    padding-bottom: 16px !important;
  }

  .menu-links--submenu {
    border-bottom: none;
    animation: none;
    opacity: 1;
    padding: 0 8px;
  }
}
</style>
