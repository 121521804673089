<script setup>
defineProps({
  menu: {
    type: String,
    default: 'userMenu'
  }
})

const { closeDrawer } = useDrawer()
const { signOut } = useAuthLib()
const { $toast, $label, $sentryCaptureException } = useNuxtApp()
const isLoggingOut = ref(false)

async function logout() {
  try {
    isLoggingOut.value = true
    await signOut()
    closeDrawer()
  } catch (error) {
    $toast.error($label('messages.errorOccuredMessage'))
    $sentryCaptureException(error)
  } finally {
    isLoggingOut.value = false
  }
}
</script>

<template>
  <div :class="['user-menu', `user-menu--${menu}`]">
    <nav>
      <NuxtLink to="/boeken" class="menu-links" aria-label="boeken">
        {{ $label('account.profile.myBooks') }}
      </NuxtLink>
      <NuxtLink to="/shopboeken" class="menu-links" aria-label="shopboeken">
        {{ $label('account.profile.myShopPurchases') }}
      </NuxtLink>
      <NuxtLink
        to="/account"
        class="menu-links"
        aria-label="account"
        data-nw="go-to-profile-page"
      >
        {{ $label('account.profile.myAccount') }}
      </NuxtLink>
    </nav>
    <a
      href="#"
      data-nw="logout-btn"
      aria-label="log out"
      :class="[
        'logout-btn btn btn-secondary w-100 mt-48',
        { loading: isLoggingOut }
      ]"
      @click.prevent="logout"
      >{{ $label('common.header.signOut') }}</a
    >
  </div>
</template>

<style lang="scss" scoped>
.menu-links {
  border-bottom: 1px solid $grey-02;
  display: block;
  padding: 12px 0;
  font-weight: normal;
}

a {
  opacity: 0;
}

@for $i from 1 through 8 {
  a:nth-child(#{$i}n) {
    animation: fadeInUp 0.3s forwards ease-out;
    animation-delay: #{$i * 0.2}s;
  }
}

.logout-btn {
  animation-delay: 0.8s !important;
}

.user-menu--defaultMenu {
  @for $i from 1 through 8 {
    a:nth-child(#{$i}n) {
      animation: fadeInUp 0.3s forwards ease-out;
      animation-delay: #{$i * 0.2 + 0.5}s;
    }
  }

  .logout-btn {
    animation-delay: 1.4s !important;
  }
}
</style>
