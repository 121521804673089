<template>
  <VeeForm
    v-slot="{ meta: formMeta }"
    data-nw="profile-birthdate-form"
    @submit="submit"
  >
    <div class="mb-16">
      <label for="birthday" class="form-label">
        {{ $label('account.profile.birthday') }}
      </label>
      <VeeField
        v-slot="{ meta, field, errorMessage }"
        v-model="birthday"
        name="birthday"
        :rules="{
          regex: /^\d{4}-\d{2}-\d{2}|\d{2}-\d{2}-\d{4}$/,
          required: true
        }"
        validate-on-mount
      >
        <input
          v-bind="field"
          id="birthday"
          type="date"
          :is-valid="meta.valid && meta.touched"
          :class="[
            'form-control',
            { 'is-valid': meta.valid && meta.touched },
            { 'is-invalid': !meta.valid && meta.touched }
          ]"
          aria-describedby="birthday"
        />
        <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
          {{ errorMessage }}
        </div>
      </VeeField>
    </div>

    <BaseButton
      :disabled="isLoading || !formMeta.valid"
      :class="{ loading: isLoading }"
      class="mt-16 w-100"
      variant="primary"
      type="submit"
      data-nw="profile-birthday-submit"
    >
      {{ $label('common.save') }}
    </BaseButton>
  </VeeForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ValidationMixin from '~/mixins/validation'

export default {
  mixins: [ValidationMixin],
  data() {
    return {
      isLoading: false,
      birthday: ''
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'loggedInUser'
    })
  },
  created() {
    this.birthday = this.loggedInUser.birthday
  },
  methods: {
    ...mapActions({
      updateProfile: 'account/updateProfile',
      updateDrawerState: 'updateDrawerState'
    }),
    async submit() {
      try {
        this.isLoading = true
        await this.updateProfile({
          birthday: this.prepareBirthday(this.birthday)
        })

        this.$toast.success(
          this.$label('messages.updateProfile.successMessage')
        )

        this.updateDrawerState({
          isMenuShown: false
        })
      } catch (error) {
        this.$sentryCaptureException(error)
        this.$toast.error(this.$label('messages.errorOccuredMessage'))
      } finally {
        this.isLoading = false
      }
    },
    prepareBirthday(birthday) {
      const matches = birthday.match(/^(\d{2})-(\d{2})-(\d{4})$/)
      if (matches) {
        return `${matches[3]}-${matches[2]}-${matches[1]}`
      }

      return birthday
    }
  }
}
</script>
